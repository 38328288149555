<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.nfcreader.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.nfcreader.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.getnfcreaderlist();
  },
  methods: {
    getnfcreaderlist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getcardreaderlist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.messagelist = response.data.data;
          console.log(that.messagelist);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    update(sn) {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "cardreadermakeupdate",
            sn,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status == 200) {
            that.$message({
              message: $t("menuitems.clouds.nfcreader.command.success"),
              type: "success",
            });
          } else {
            that.$message.error($t("menuitems.clouds.nfcreader.command.fail"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <p>
              <router-link to="/clouds/nfcreader/add">
                <el-button type="primary">{{
                  $t("menuitems.clouds.nfcreader.bind")
                }}</el-button>
              </router-link>
            </p>
            <el-table :data="messagelist" stripe style="width: 100%">
              <el-table-column
                prop="mac"
                :label="$t('menuitems.clouds.nfcreader.devicesn')"
              >
              </el-table-column>
              <el-table-column
                :label="$t('menuitems.clouds.nfcreader.version')"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.version }}&nbsp;</span>
                  <el-link
                    type="primary"
                    v-if="scope.row.version < scope.row.newversion"
                    @click="update(scope.row.mac)"
                    ><i class="el-icon-upload"></i>
                    {{ $t("menuitems.clouds.nfcreader.upgrade") }}</el-link
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="note"
                :label="$t('menuitems.clouds.nfcreader.note')"
              >
              </el-table-column>
              <el-table-column :label="$t('menuitems.clouds.nfcreader.status')">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 1">{{
                    $t("menuitems.clouds.nfcreader.online")
                  }}</span>
                  <span v-else>{{
                    $t("menuitems.clouds.nfcreader.offline")
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>